main {
    overflow: hidden;
    min-height: 100vh;
    display: flex;
    flex-flow: column nowrap;
    /* justify-content: space-between; */
}

main div:last-of-type {
    margin-top: auto;
}

#qr {
    display: flex;
    justify-content: space-evenly;
}

#qr-p {
    text-align: center;
    font-size: min(calc(100vw*50/var(--device)), 50px);
    font-weight: 700;
    padding: 0 min(calc(100vw*93/var(--device)), 93px);
    margin-top: min(calc(100vw*93/var(--device)), 93px);
    margin-bottom: min(calc(100vw*20/var(--device)), 20px);
    line-height: 1;
}
