@import url('https://fonts.googleapis.com/css2?family=Raleway&family=Roboto&display=swap');

:root {
    --device: 1440;
}

#product {
    display: flex;
    flex-flow: column nowrap;
    font-family: 'Roboto', sans-serif;
    padding: 0 min(calc(100vw*115/var(--device)), 115px);
    position: relative;
}

#pr-disc {
    position: absolute;
    background-color: #F87A60;
    color: #FFFFFF;
    border-radius: min(calc(100vw*40/var(--device)), 40px);
    font-size: min(calc(100vw*36/var(--device)), 36px);
    padding: min(calc(100vw*10/var(--device)), 10px) min(calc(100vw*20/var(--device)), 20px);
}

#pr-img {
    padding: 0 min(calc(100vw*200/var(--device)), 200px);
    margin-bottom: min(calc(100vw*60/var(--device)), 60px);
}

#pr-name {
    font-size: min(calc(100vw*42/var(--device)), 42px);
}

.pr-atr {
    color: #EE5130;
    font-size: min(calc(100vw*36/var(--device)), 36px);
    margin: min(calc(100vw*20/var(--device)), 20px) 0 0 0 ;
}

#pr-wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: min(calc(100vw*43/var(--device)), 43px);
    margin: min(calc(100vw*20/var(--device)), 20px) 0;
}

#pr-value {
    font-size: min(calc(100vw*36/var(--device)), 36px);
    color: #B5B5B5;
}

#pr-price {
    font-size: min(calc(100vw*55/var(--device)), 55px);
}

#pr-price s {
    font-size: min(calc(100vw*36/var(--device)), 36px);
    color: #5ABA0C;
    margin-right: min(calc(100vw*40/var(--device)), 40px);
    vertical-align: middle;
}

#pr-tabs {
    display: flex;
}

.tab {
    height: min(calc(100vw*110/var(--device)), 110px);
    margin-right: min(calc(100vw*25/var(--device)), 25px);
    font-size: min(calc(100vw*32/var(--device)), 32px);
    border-top-right-radius: min(calc(100vw*20/var(--device)), 20px);
    border-top-left-radius: min(calc(100vw*20/var(--device)), 20px);
    padding: min(calc(100vw*22/var(--device)), 22px) min(calc(100vw*26/var(--device)), 26px);
    cursor: pointer;
}

#pr-tabs p:nth-of-type(1) {
    width: min(calc(100vw*250/var(--device)), 250px);
}

#pr-tabs p:nth-of-type(2) {
    width: min(calc(100vw*365/var(--device)), 365px);
}

.active {
    background-color: #E6F5DB;
    color: #5ABA0C;
}

.n-active {
    background-color: #EEEEEE;
    color: #858585;
}

#pr-desc-wrap {
    box-shadow: 0 0 min(calc(100vw*20/var(--device)), 20px) 0 rgba(0, 0, 0, 0.1);
    border-radius: min(calc(100vw*20/var(--device)), 20px);
    height: fit-content;
    padding: min(calc(100vw*30/var(--device)), 30px);
    font-size: min(calc(100vw*36/var(--device)), 36px);
    background-color: #fff;
}

#pr-desc-wrap > *:not(:last-child) {
    margin-bottom: min(calc(100vw*20/var(--device)), 20px);
}

#pr-desc {
    position: relative;
}

#pr-desc div:nth-of-type(1) {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
}
#pr-desc div:nth-of-type(2) {
    position: relative;
    margin-top: min(calc(100vw*80/var(--device)), 80px);
    z-index: 1;
}

#pr-mfr p {
    font-size: min(calc(100vw*36/var(--device)), 36px);
    color:#B5B5B5;
    margin-bottom: min(calc(100vw*30/var(--device)), 30px);
}

#pr-mfr hr {
    border: 1px solid #E5E5E5;
}

@media screen and (max-width: 600px) {
    :root {
        --device: 1080;
    }
    #pr-img {
        padding: 0;
    }
    #pr-desc {
        margin-bottom: min(calc(100vw*40/var(--device)), 40px);
    }
}
