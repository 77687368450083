@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700&display=swap');

*,
*::after,
*::before {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

html, body {
	/* max-width: 1440px; */
	font-family: 'Montserrat', sans-serif;
	margin: auto;
}

section {
	position: relative;
}

.platform {
	width: 50%;
    margin: 10px auto;
    display: flex;
    justify-content: space-between;
}
.platform img {
	height: calc(100vw*55/1440);
	max-height: 55px;
}
h1 {
	font-size: min(calc(100vw*100/1440), 100px);
	text-align: center;
	margin-bottom: 10px;
}
h3 {
	font-size: min(calc(100vw*48/1440), 48px);
	text-align: center;
	font-weight: bold;
	margin-bottom: min(calc(100vw*40/1440), 40px);
}

.phone {
	position: relative;
    height: min(calc(100vw*1200/1440),1200px);
    background-image: url('../public/img/phone.png');
    background-repeat: no-repeat;
    background-position-x: center;
    /* background-position-y: min(calc(100vw*90/1440),90px); */
    background-size: min(calc(100% * 500 / 1440), 500px);
    margin-top: min(calc(100vw*90/1440),90px);
}

/* .phone :nth-child(1) {
	top: min(calc(100vw*50/1440), 50px);
	margin-left: auto;
	margin-right: auto;
    position: absolute;
    left: 0;
    right: 0;
	width: calc(100% * 360 / 1440);
    max-width: 360px;
} */
.phone :nth-child(1) {
	right: 0px;
    left: min(calc(100vw*600/1440),600px);
    position: absolute;
    margin: auto;
    z-index: -1;
    width: calc(100vw * 350 / 1440);
    max-width: 350px;
    bottom: min(calc(100vw*100/1440), 100px);
}
.phone :nth-child(2) {
	position: absolute;
    left: 0;
    right: min(calc(100vw*490/1440),490px);
    bottom: min(calc(100vw*50/1440), 50px);
    margin: auto;
    z-index: 2;
    width: calc(100vw* 400 / 1440);
    max-width: 400px;
}

.phone :nth-child(3) {
	top: min(calc(100vw*20/1440), 20px);
	position: absolute;
	width: calc(100vw * 90 / 1440);
    max-width: 90px;
	left: min(calc(100vw*200/1440), 200px);
}
.phone :nth-child(4) {
	position: absolute;
    top: min(calc(100vw*500/1440), 500px);
    width: calc(100vw * 300 / 1440);
    max-width: 300px;
    left: max(calc(100vw*150/1440*-1),-150px);
}
.phone :nth-child(5) {
	position: absolute;
    top: max(calc(100vw*200/1440*-1),-200px);
    width: calc(100vw * 200 / 1440);
    max-width: 200px;
    right: 0;
}

.bonus {
	background-color: #59B90C;
    height: min(calc(100vw*600/1440),600px);
    position: relative;
}

.bonus :nth-child(1) {
	position: absolute;
    top: min(calc(100vw*30/1440), 30px);
    left: min(calc(100vw*30/1440), 30px);
	max-width: 700px;
	width: calc(100vw*700/1440);
}
.bonus :nth-child(2) {
	position: absolute;
    bottom: min(calc(100vw*15/1440),15px);
    right: min(calc(100vw*30/1440),30px);
	width: min(calc(100vw*650/1440), 650px);
}
.bonus :nth-child(3) {
	position: absolute;
    font-size: min(calc(100vw*50/1440),50px);
	font-weight: 800;
    text-align: right;
    right: min(calc(100vw*90/1440),90px);
    top: min(calc(100vw*70/1440),70px);;
    color: #FFFFFF;
}
.bonus :nth-child(4) {
	position: absolute;
    font-size: min(calc(100vw*40/1440),40px);
	font-weight: 800;
    text-align: right;
    right: min(calc(100vw*90/1440),90px);
    top: min(calc(100vw*120/1440),120px);
    color: #FFFFFF;
}

.bonus :nth-child(5), .bonus :nth-child(6), .bonus :nth-child(7), .bonus :nth-child(8) {
	display: none;
}

@media screen and (max-width: 600px) {
	.platform {
		display: none;
	}
	.phone :nth-child(1) {
		width: min(calc(100vw*70/500), 70px);
		top: min(calc(100vw*230/500), 230px);
		left: min(calc(100vw*150/500), 150px);
	}
	.phone :nth-child(2) {
		width: min(calc(100vw*100/500), 100px);
    	/* top: min(calc(100vw*220/500), 220px); */
	}
	.phone :nth-child(5) {
		top: max(calc(100vw*60/500*-1), -60px);
	}
	.bonus img:nth-of-type(1) {
		display: none;
	}
	.bonus :nth-child(2) {
		top: 80px;
		left: 20px;
		width: min(calc(100vw*900/1440), 900px);
	}
	.bonus :nth-child(3) {
		right: unset;
		left: min(calc(100vw*20/500),20px);
		line-height: min(calc(100vw*50/500),50px);
		font-size: min(calc(100vw*26/500),26px);
	}
	.bonus :nth-child(4) {
		right: unset;
		left: 20px;
		line-height: min(calc(100vw*80/500),80px);
		font-size: min(calc(100vw*16/500),16px);
	}
	
	.bonus :nth-child(5) {
		display: unset;
		position: absolute;
		bottom: 0;
		right: 10px;
		width: min(calc(100vw*250/500),250px);
	}
	.bonus :nth-child(6), .bonus :nth-child(7), .bonus :nth-child(8) {
		display: unset;
	}
	.bonus :nth-child(6) img{
		width: min(calc(100vw*190/500),190px);
		height: auto;
		top: min(calc(100vw*250/400), 250px);
		left: 20px;
		display: unset;
	}
	.bonus :nth-child(7) img{
		width: min(calc(100vw*190/500),190px);
		height: auto;
		top: min(calc(100vw*325/400), 325px);
		left: 20px;
		display: unset;
	}
	.bonus :nth-child(8) img{
		width: min(calc(100vw*190/500),190px);
		height: auto;
		top: min(calc(100vw*395/400), 395px);
		left: 20px;
		display: unset;
	}
	
	.qr {
		display: none;
	}
	footer {
		background-color: #FFFFFF;
		color: #000000;
	}
	.bonus {
		height: min(calc(100vh*500/700), 500px);
	}
	header {
		margin-bottom: min(calc(100vw*50/600),50px);
	}
}