.desc-tbl {
    width: 100%;
    padding: 0 min(calc(100vw*5/var(--device)), 5px);
}

.desc-tbl th {
    text-align: left;
    font-weight: 400;
}

.desc-tbl td {
    text-align: right;
    font-size: min(calc(100vw*32/var(--device)), 32px);
    color: #858585;
    padding-bottom: min(calc(100vw*15/var(--device)), 15px);
}