.table-head {
	display: flex;
	justify-content: space-between;
	padding: min(calc(100vw*20/var(--device)),20px);
	margin-bottom: min(calc(100vw*50/600),50px);
}

.table-head img {
	width: calc(100% * 360 / var(--device));
    max-width: 360px;
}

.call {
	margin: auto 0;
	background-color: #59B90C;
	/* padding: calc(100% *10/var(--device)); */
	border: 1px solid #84CB4B;
	padding-inline: 25px;
	border-radius: 5px;
	line-height: min(calc(100vw*60/var(--device)),60px);
}

.call a:active, /* активная/посещенная ссылка */
.call a:hover,  /* при наведении */
.call a {
	text-decoration: none;
	color: rgb(255, 255, 255);
	font-size: min(calc(100vw*28/var(--device)), 28px);
	vertical-align: middle;
	line-height: min(calc(100vw*40/var(--device)), 40px);
}

/* .phone-head {
	background-color: #5ABA0C;
	position: relative;
	height: calc(100vw*125/var(--device));
	display: flex;
	align-items: center;
	overflow: hidden;
}

.phone-head h1 {
	font-size: min(calc(100vw*55/var(--device)), 55px);
	font-weight: 400;
	text-align: left;
	color: #FFFFFF;
	font-family: 'Roboto';
	margin: 0;
	margin-left: min(calc(100vw*83/var(--device)), 83px);
}

.phone-head img:nth-of-type(1) {
	position: absolute;
	right: min(calc(100vw*154/var(--device)), 154px);
	top: min(calc(100vw*20/var(--device)*-1), -20px);
	width: calc(100vw*250/var(--device));
}
.phone-head img:nth-of-type(2) {
	position: absolute;
	right: min(calc(100vw*80.5/var(--device)), 80.5px);
	top: min(calc(100vw*60/var(--device)*-1), -60px);
	width: calc(100vw*123.5/var(--device));
}
.phone-head img:nth-of-type(3) {
	position: absolute;
	right: min(calc(100vw*20/var(--device)*-1), 20px);
	top: min(calc(100vw*30/var(--device)), 30px);
	width: calc(100vw*200/var(--device));
} */