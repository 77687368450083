footer {
    position: relative;
}

#footer {
	display: flex;
    padding-left: min(calc(100vw*100/var(--device)),100px);
    padding-right: min(calc(100vw*100/var(--device)),100px);
    align-items: center;
    justify-content: space-between;
    background-color: #FF502A;
    height: calc(100vw*80/var(--device));
    max-height: 80px;
	font-size: min(calc(100vw*20/var(--device)),20px);
	color: #FFFFFF;
    position: relative;
    z-index: 100;
}

#footer :last-child {
    text-align: center;
    margin-top: 0;
}

#img-beet  {
	position: absolute;
    width: calc(100vw*180/var(--device));
    max-width: 180px;
    rotate: -27.2deg;
    bottom: max(calc(100vw*71/var(--device)*-1), -71px);
}

#img-carrot  {
	position: absolute;
    width: calc(100vw*65/var(--device));
    max-width: 65px;
    rotate: 70deg;
    left: 200px;
    right: 0;
    margin: auto;
    bottom: max(calc(100vw*44/var(--device)*-1), -44px);
}

#img-kiwi  {
	position: absolute;
    width: calc(100vw*100/var(--device));
    max-width: 100px;
    right: min(calc(100vw*50/var(--device)),50px);
    bottom: max(calc(100vw*84/var(--device)), 84px);
}

/* .ico img {
	width: calc(100vw*40/var(--device));
	max-width: 40px;
	margin-left: min(calc(100vw*10/var(--device)),10px);
} */