.qr {
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    height: min(calc(100vw*520/var(--device)), 520px);
}

.img-qr {
    margin: min(calc(100vw*30/var(--device)), 30px)  auto;
    width: calc(100vw*300/var(--device));
	max-width: 300px;
}

.qr-mkt {
    width: calc(100vw*150/var(--device));
	max-width: 150px;
}